import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Btn, Ic } from '@/components/basic/index';
import { getReportAllIds } from '@/api/index';
import ReportForm from './ReportForm';
import style from '@/pages/report/Report.scss';

const Reports = ({ studentId, reportId, close }) => {
  const [allIds, setAllIds] = useState([]);
  const [page, setPage] = useState([0, 0]);
  const [targetIds, setTargetIds] = useState([null, null]);
  const getId = (ids, idx) => (ids[idx] ? ids[idx].id : null);
  const handlePrev = async () => {
    const n = page[0] - 1;
    if (n < 1) return;
    setPage([n, page[1]]);
    setTargetIds([getId(allIds, n - 1), getId(allIds, n - 2)]);
  };
  const handleNext = async () => {
    const n = page[0] + 1;
    if (n > page[1]) return;
    setPage([n, page[1]]);
    setTargetIds([getId(allIds, n - 1), getId(allIds, n - 2)]);
  };
  const getAllidsWithSetPage = async () => {
    const ids = await getReportAllIds(studentId);
    setAllIds(ids);
    const targetId = targetIds[0] === null ? reportId : targetIds[0];
    const index = ids.findIndex((r) => r.id === targetId);
    setPage([index + 1, ids.length]);
    setTargetIds([getId(ids, index), getId(ids, index - 1)]);
  };
  const handleBack = async () => {
    close();
  };
  useEffect(async () => {
    await getAllidsWithSetPage();
  }, []);
  useEffect(() => {}, [allIds]);

  return (
    <Container fluid>
      <div className={style.reportEditPanel}>
        {allIds.length !== 0 ? (
          <Card className={style.header}>
            <Row>
              <Col xs={3} style={{ textAlign: 'right' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handlePrev}>
                  <Ic iName="faAnglesLeft" />
                </Btn>
              </Col>
              <Col xs={6}>
                <Row className={style.title}>
                  <Col>
                    {' '}
                    {page[0]}回 / {page[1]}回{' '}
                  </Col>
                </Row>
              </Col>
              <Col xs={3} style={{ textAlign: 'left' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handleNext}>
                  <Ic iName="faAnglesRight" />
                </Btn>
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <ReportForm
          studentId={studentId}
          targetReportId={targetIds[0]}
          prevReportId={targetIds[1]}
          onBack={handleBack}
        />
      </div>
    </Container>
  );
};

export default Reports;
