import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
import { logout } from '@/util/authentication';
import { me } from '@/api/index';
import { isNoNeedLogins } from '@/routes/index';
import { Btn } from '@/components/basic/index';
import style from './Layout.scss';

const { company, functions, reportUsageIds } = window.env;

const Layout = ({ children }) => {
  const history = useHistory();
  const noNeedLogin = isNoNeedLogins(history.location.pathname);
  const [canUseReport, setCanUseReport] = useState(false);

  useEffect(() => {
    (async () => {
      if (noNeedLogin) {
        return;
      }
      const iam = await me();
      if (!iam) {
        doLogout();
      }

      if (functions.includes('report')) {
        setCanUseReport(true);
        return;
      }
      if (iam === null) {
        return;
      }
      setCanUseReport(reportUsageIds.includes(iam.id));
    })();
  }, []);

  const doLogout = (e) => {
    logout();
    document.location.href = '/login';
  };
  const moveTo = (to) => {
    history.push(to);
  };
  return (
    <>
      <Toaster />
      <Navbar className={`me-auto ${style.topBar}`}>
        <Nav className="me-auto">
          <NavItem className={style.brand}>{company.brandName}</NavItem>
        </Nav>
        <Nav className="justify-content-end">
          {!noNeedLogin && (
            <div className={`btn-group ${style.menuPanel}`}>
              <Btn
                isCustom={true}
                className={style.dropdownBtn}
                width="35px"
                height="35px"
                dataToggle="dropdown"
                ariaHaspopup="true"
                ariaExpanded="false"
                circle
                prefixIcon="faBars"
              />
              <div className={`dropdown-menu dropdown-menu-right ${style.dropDownPanel}`}>
                {canUseReport && (
                  <button className="dropdown-item" type="button" onClick={() => moveTo('/reportTop')}>
                    指導報告書
                  </button>
                )}
                {functions.includes('contract') && (
                  <button className="dropdown-item" type="button" onClick={() => moveTo('/')}>
                    ご契約内容
                  </button>
                )}
                <button className="dropdown-item" type="button" onClick={doLogout}>
                  ログアウト
                </button>
              </div>
            </div>
          )}
        </Nav>
      </Navbar>
      <div className="contents">
        <section className={style.pageContainer}>
          <main>{children}</main>
        </section>
      </div>
    </>
  );
};

export default Layout;
