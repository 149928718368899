import { apiBase } from './ApiBase';
import camelcaseKeys from 'camelcase-keys';
import Family from '@/entity/Family';
import { Exam, Record, Report } from '@/entity/Report';
import Settlement from '@/entity/Settlement';

const toCamel = (data) => (data === '' ? undefined : camelcaseKeys(data, { deep: true }));
export const auth = async ({ userId, password }) => {
  const result = await apiBase.post(`/api/v1/auth`, { user_id: userId, password });
  return result.data.token;
};

export const me = async () => {
  try {
    const result = await apiBase.get(`/api/v1/me`);
    return new Family(camelcaseKeys(result.data, { deep: true }));
  } catch (_) {
    return null;
  }
};

export const getPurchaseOf = async ({ id }) => {
  const res = await apiBase.get(`/api/v1/purchase/${id}`);
  return camelcaseKeys(res.data, { deep: true });
};

export const getPurchase = async () => {
  const res = await apiBase.get(`/api/v1/currentPurchase`);
  return camelcaseKeys(res.data, { deep: true });
};

export const getAllPurchase = async () => {
  const res = await apiBase.get(`/api/v1/purchase`);
  return camelcaseKeys(res.data, { deep: true });
};

export const getServiceProvider = async () => {
  const res = await apiBase.get(`/api/v1/serviceProvider`);
  return camelcaseKeys(res.data, { deep: true });
};

export const saveSign = async ({ familyId, sign }) => {
  const result = await apiBase.put(`/api/v1/${familyId}/sign`, { sign });
  return result.data;
};

export const confirmPurchase = async ({
  id,
  sign,
  multiGradeReason,
  confirm13Items,
  acknowledgment,
  allowElectronicDelivery,
}) => {
  const result = await apiBase.put(`/api/v1/purchase/${id}/confirm`, {
    sign,
    multiGradeReason,
    confirm13Items,
    acknowledgment,
    allowElectronicDelivery,
  });
  return result.data;
};

export const forgotPassword = async ({ mail }) => {
  const result = await apiBase.post(`/api/v1/forgotPassword`, { mail });
  return result.data;
};

export const verifyResetToken = async ({ resetToken }) => {
  const result = await apiBase.post(`/api/v1/verifyResetToken`, { resetToken });
  return result.data;
};

export const resetPassword = async ({ resetToken, password }) => {
  const result = await apiBase.put(`/api/v1/resetPassword`, { resetToken, password });
  return result.data;
};

export const getResponderList = async () => {
  const result = await apiBase.get(`/api/v1/myResponder`);
  return camelcaseKeys(result.data, { deep: true });
};

export const getStudent = async () => {
  const result = await apiBase.get(`/api/v1/students`);
  return camelcaseKeys(result.data, { deep: true });
};

export const getReportAllIds = async (studentId) => {
  const result = await apiBase.get(`/api/v1/students/${studentId}/reportIds`);
  return result.data.reverse();
};

export const getReports = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/reports`);
  return toCamel(data).map((r) => new Report(r, true));
};

export const getReport = async (studentId, reportId) => {
  const result = await apiBase.get(`/api/v1/students/${studentId}/reports/${reportId}`);
  return new Report(toCamel(result.data));
};

export const getExams = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/exams`);
  return toCamel(data).map((r) => new Exam(r));
};

export const addExamImage = async (studentId, examId, form) => {
  const result = await apiBase.post(`/api/v1/students/${studentId}/exams/${examId}/images`, form);
  return result;
};

export const getExamImage = async (studentId, examId, imageId) => {
  const { data } = await apiBase.get(
    `/api/v1/students/${studentId}/exams/${examId}/images/${imageId}`,
    {},
    { responseType: 'blob' }
  );
  return data;
};

export const deleteExamImage = async (studentId, examId, imageId) => {
  const result = await apiBase.delete(`/api/v1/students/${studentId}/exams/${examId}/images/${imageId}`);
  return result;
};

export const getRecords = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/records`);
  return toCamel(data).map((r) => new Record(r));
};

export const addRecordImage = async (studentId, recordId, form) => {
  const result = await apiBase.post(`/api/v1/students/${studentId}/records/${recordId}/images`, form);
  return result;
};

export const deleteRecordImage = async (studentId, recordId, imageId) => {
  const result = await apiBase.delete(`/api/v1/students/${studentId}/records/${recordId}/images/${imageId}`);
  return result;
};

export const getRecordImage = async (studentId, recordId, imageId) => {
  const { data } = await apiBase.get(
    `/api/v1/students/${studentId}/records/${recordId}/images/${imageId}`,
    {},
    { responseType: 'blob' }
  );
  return data;
};

export const submitReport = async (studentId, reportId, report) => {
  const result = await apiBase.put(`/api/v1/students/${studentId}/reports/${reportId}/approval`, report);
  return new Report(toCamel(result.data));
};

export const getSettlements = async () => {
  const result = await apiBase.get(`/api/v1/families/settlements`);
  return camelcaseKeys(result.data, { deep: true });
};

export const getSettlement = async (familyId, settlementId) => {
  const result = await apiBase.get(`/api/v1/families/${familyId}/settlements/${settlementId}`);
  return new Settlement(toCamel(result.data));
};

export const getFile = async ({ id }) => {
  const res = await apiBase.get(`/api/v1/file/${id}`);
  return camelcaseKeys(res.data, { deep: true });
};

export const getFileContent = async ({ id }) => {
  const res = await apiBase.get(`/api/v1/file/${id}/content`);
  return res.data;
};

export const getContractFileFor = async ({ id }) => {
  return apiBase.get(`/api/v1/purchase/${id}/contractFile`, {}, { responseType: 'blob' });
};

// const fileNameOf = ({ headers }) => {
//   const disposition = headers['content-disposition']
//   const mayFilename = disposition.split(/; /).filter((d) => {
//     return d.toLowerCase().startsWith("filename*=utf-8''")
//   })
//   return mayFilename.length === 0 ? 'ダウンロードファイル' : decodeURIComponent(mayFilename[0].toLowerCase().replace("filename*=utf-8''", ''))
// }
